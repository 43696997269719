import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { builder, Builder, BuilderComponent } from '@builder.io/react';
import loadable from '@loadable/component';
import '../../static/css/funnel.css';
import { FunnelProvider } from '../context/FunnelContext';

const Helmet = loadable(() => import('react-helmet'));

builder.init(process.env.GATSBY_BUILDER_API_KEY);

const defaultTitle = 'PetLab Co.';
const defaultDescription = 'Supplies, Treats, Snacks & Chews - Join The Pack';

function FunnelTemplate(props) {
  const {
    data,
    path,
    location: { href: funnelUrl }
  } = props;

  let funnelContent = ``;

  switch (Builder.previewingModel) {
    case 'quiz':
      funnelContent = data.allBuilderModels.oneQuiz.content;
      break;
    case 'sales-letter':
      funnelContent = data.allBuilderModels.oneSalesLetter.content;
      break;
    case 'slide':
      funnelContent = data.allBuilderModels.oneSlide.content;
      break;
    default:
    case 'funnel':
      funnelContent = data.allBuilderModels.oneFunnel2024.content;
      break;
  }

  const funnelDesign = `design2`;
  const productSelectorData = data.funnelPageData?.productSelector;
  const defaultCategory = `subscriptions`;
  const defaultTubIndex = 0;
  const defaultProduct = `subscription_${defaultTubIndex}`;

  const [currentCategory, setCurrentCategory] = useState(defaultCategory);
  const [currentProduct, setCurrentProduct] = useState(defaultProduct);
  const [tubIndex, setTubIndex] = useState(defaultTubIndex);
  const [currentTubIndex, setCurrentTubIndex] = useState(defaultTubIndex);
  const [productImageIndex, setProductImageIndex] = useState(0);
  const [frequency, setFrequency] = useState('1:month');
  const [bumpOfferChecked, setBumpOfferChecked] = useState(false);
  const [tubIndexChecked, setTubIndexChecked] = useState(false);
  const [bumpOfferIndex, setBumpOfferIndex] = useState(0);
  const [currentTub, setCurrentTub] = useState({});
  const [showSavingsPopup, setShowSavingsPopup] = useState(false);
  const [submittedToCart, setSubmittedToCart] = useState(false);
  const [currentLayer, setCurrentLayer] = React.useState(`layer-0`);
  const [previousLayer, setPreviousLayer] = React.useState([]);
  const [localCartItems, setLocalCartItems] = React.useState([]);
  const [funnelUpdated, setFunnelUpdated] = React.useState(false);
  const [answers, setAnswers] = React.useState({});
  const [extraObjects, setExtraObjects] = useState({
    ...JSON.parse(productSelectorData?.extra || '{}'),
    is_tub_index_checked: 'no',
    currentCategory
  });

  if (!Builder.isEditing) return null;

  const context = {
    ...productSelectorData,
    currentCategory: currentCategory,
    setCurrentCategory: setCurrentCategory,
    currentProduct: currentProduct,
    setCurrentProduct: setCurrentProduct,
    path: path,
    funnelUrl: funnelUrl,
    pageDesign: funnelDesign,
    tubIndex: tubIndex,
    setTubIndex: setTubIndex,
    currentTubIndex: currentTubIndex,
    productImageIndex: productImageIndex,
    setProductImageIndex: setProductImageIndex,
    setCurrentTubIndex: setCurrentTubIndex,
    frequency: frequency,
    setFrequency: setFrequency,
    bumpOfferChecked: bumpOfferChecked,
    setBumpOfferChecked: setBumpOfferChecked,
    bumpOfferIndex: bumpOfferIndex,
    setBumpOfferIndex: setBumpOfferIndex,
    currentTub: currentTub,
    setCurrentTub: setCurrentTub,
    showSavingsPopup: showSavingsPopup,
    setShowSavingsPopup: setShowSavingsPopup,
    submittedToCart: submittedToCart,
    setSubmittedToCart: setSubmittedToCart,
    currentLayer: currentLayer,
    setCurrentLayer: setCurrentLayer,
    previousLayer: previousLayer,
    setPreviousLayer: setPreviousLayer,
    answers: answers,
    setAnswers: setAnswers,
    extraObjects: extraObjects,
    setExtraObjects: setExtraObjects,
    tubIndexChecked: tubIndexChecked,
    setTubIndexChecked: setTubIndexChecked,
    localCartItems: localCartItems,
    setLocalCartItems: setLocalCartItems,
    funnelUpdated: funnelUpdated,
    setFunnelUpdated: setFunnelUpdated
  };

  return (
    <>
      <FunnelProvider value={context}>
        <Helmet
          bodyAttributes={{
            class: `petlab-funnel-1 ${funnelDesign}`
          }}
          htmlAttributes={{
            lang: 'en'
          }}
        >
          <title>
            {(funnelContent && funnelContent.data?.siteMeta?.title) ||
              defaultTitle}
          </title>
          <meta
            name="description"
            content={
              (funnelContent && funnelContent.data?.siteMeta?.description) ||
              defaultDescription
            }
          />
          <meta name="funnel-id" content={funnelContent?.id} />
          <meta
            name="product-selector-id"
            content={funnelContent?.data?.products?.id}
          />
          <meta
            name="magic-key"
            content="t54cdW3cbU45TWPRtYGUR4fyBnWHe49jWeuqdRStfAvELctRyfyXLmDteF9Cf9jhPsyzDfkcCK5DNU6Pr87HU8tgNKBDDmeHDFQn"
          />
        </Helmet>
        {/** name of the model is landing page, change it if you decided to build*/}
        <BuilderComponent
          name={Builder.previewingModel}
          content={funnelContent}
          data={{
            ...context
          }}
        ></BuilderComponent>
      </FunnelProvider>
    </>
  );
}

export const query = graphql`
  query {
    allBuilderModels {
      oneFunnel2024(options: { cachebust: true }) {
        content
      }
      oneQuiz(options: { cachebust: true }) {
        content
      }
      oneSalesLetter(options: { cachebust: true }) {
        content
      }
      oneSlide(options: { cachebust: true }) {
        content
      }
    }

    funnelPageData {
      id
      funnelId
      productSelector {
        upsell
        currency {
          code
          symbol
        }
        onetime {
          discounts
          products {
            data {
              quantity
              variantId
              checkoutTitle
            }
            checkoutData {
              country
              discounted_price
              image
              price
              perceived_rrp
              product_id
              quantity
              splitFlag
              split_price
              title
              title_index
              variant_id
            }
            discount_percentage
            display_title
            freeGiftData {
              quantity
              variantId
              checkoutTitle
            }
            free_gift_value
            free_gift_discount_code
            free_gift_checkout_title
            productRef {
              checkoutTitle
            }
            tags
            discount_code_override
            upsell_url
            additionalFreeGifts {
              checkoutTitle
              freeGiftCheckoutTitle
              freeGiftDiscountCode
              freeGiftValue
              quantity
              variantId
              freeGiftQuantity
            }
          }
          bumpoffers {
            checkoutData {
              country
              discounted_price
              image
              price
              perceived_rrp
              product_id
              quantity
              splitFlag
              split_price
              title
              title_index
              variant_id
            }
            data {
              quantity
              variantId
              checkoutTitle
            }
            productRef {
              product {
                discountCode
              }
              checkoutTitle
            }
            discount_percentage
            display_title
          }
        }

        subscription {
          discounts
          products {
            data {
              quantity
              variantId
              checkoutTitle
            }
            checkoutData {
              country
              discounted_price
              image
              price
              perceived_rrp
              product_id
              quantity
              splitFlag
              split_price
              title
              title_index
              variant_id
            }
            discount_percentage
            display_title
            freeGiftData {
              quantity
              variantId
              checkoutTitle
            }
            free_gift_value
            free_gift_discount_code
            free_gift_checkout_title
            freeSubscriptionGift {
              freeGift {
                checkoutTitle
                quantity
                variantId
              }
              flowTag
            }
            productRef {
              checkoutTitle
            }
            tags
            discount_code_override
            upsell_url
            additionalFreeGifts {
              checkoutTitle
              freeGiftCheckoutTitle
              freeGiftDiscountCode
              freeGiftValue
              quantity
              variantId
              freeGiftQuantity
            }
          }
          bumpoffers {
            checkoutData {
              country
              discounted_price
              image
              price
              perceived_rrp
              product_id
              quantity
              splitFlag
              split_price
              title
              title_index
              variant_id
            }
            data {
              quantity
              variantId
              checkoutTitle
            }
            productRef {
              product {
                discountCode
              }
              checkoutTitle
            }
            discount_percentage
            display_title
          }
        }
        extra
      }
    }
  }
`;

export default FunnelTemplate;
